<template>
  <div>
    <!-- Header-->
    <header class="game all-games">
      <div class="container h-100 px-4 mb-4 text-center">
        <div class="row h-100 d-flex justify-content-center align-items-center">
          <div class="col-lg-12">
            <h1 class="text-uppercase">how to cancel</h1>
            <div class="horizontal-divider"></div>
          </div>
        </div>
      </div>
    </header>

    <section class="my-5">
      <div class="container mb-3">
        <div class="row justify-content-center align-items-center mt-5">
          <div class="col-lg-4">
            <form @submit.prevent="onSubmit()">
              <div class="mb-3 text-left">
                <label for="exampleInputPassword1" class="form-label"
                  >Select the plan you are subscribed to:
                </label>
                <select
                  v-model="form.plan"
                  class="form-control form-control-lg"
                  required
                >
                  <option
                    v-for="(plan, i) in plans"
                    :key="i"
                    :value="plan.value"
                  >
                    {{ plan.text }}
                  </option>
                </select>
              </div>

              <!-- <div class="mb-3 text-left">
                <label for="exampleInputPassword1" class="form-label"
                  >Select Channel:
                </label>
                <select
                  v-model="form.channel"
                  class="form-control form-control-lg"
                  required
                >
                  <option
                    v-for="(channel, i) in channels"
                    :key="i"
                    :value="channel.value"
                  >
                    {{ channel.text }}
                  </option>
                </select>
              </div> -->
              <button type="submit" class="btn btn-lg btn-primary w-100">
                Submit
              </button>
              <!-- <div class="my-3">
                <router-link to="/how-to-cancel" disabled="true"
                  >Cancel</router-link
                >
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      loader: {
        loader: null,
        isLoading: true,
        fullPage: true,
      },

      form: {
        plan: "",
        channel: "47003",
      },

      plans: [
        {
          text: "Daily",
          value: "7",
        },
        {
          text: "Weekly",
          value: "10",
        },
        {
          text: "Monthly",
          value: "13",
        },
      ],

      channels: [
        { text: "Bkash", value: "47090" },
        { text: "Banglalink", value: "47003" },
      ],
    };
  },

  methods: {
    onSubmit() {
      this.loader.loader = this.$loading.show({
        // Optional parameters
        container: this.loader.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      const msisdn = this.$store.getters.GET_CANCELMOBILENUMBER;
      const channel = this.$store.getters.GET_CHANNEL;
      window.location = `https://funbox.zed.com/Bangladesh/Unsubscribe?MSISDN=${msisdn}&PLAN=${this.form.plan}&CHANNEL=${channel}`;
      this.loader.loader.hide();
      // await axios
      //   .post(
      //     `https://funbox.zed.com/Bangladesh/Unsubscribe?MSISDN=${msisdn}&PLAN=${this.form.plan}&CHANNEL=${this.form.channel}`
      //   )
      //   .then((result) => {
      //     console.log(result);
      //     this.loader.loader.hide();
      //     this.$swal(
      //       "Thank you!",
      //       "Your subscription is successfully cancelled.",
      //       "success"
      //     );
      //   })
      //   .catch((err) => {
      //     this.loader.loader.hide();
      //     console.log(err);
      //   });
    },
  },
};
</script>
<style scoped>
section.bg-gray {
  background-color: #f3f3f3;
}
</style>
