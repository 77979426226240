<template>
  <div>
    <!-- Header-->
    <header class="game all-games">
      <div class="container h-100 px-4 mb-4 text-center">
        <div class="row h-100 d-flex justify-content-center align-items-center">
          <div class="col-lg-12">
            <h1 class="text-uppercase">how to cancel</h1>
            <div class="horizontal-divider"></div>
          </div>
        </div>
      </div>
    </header>

    <section class="my-5">
      <div class="container mb-3">
        <div class="row justify-content-center align-items-center mt-5">
          <div class="col-lg-5">
            <form @submit.prevent="onSubmit()">
              <div class="mb-3 text-left">
                <label for="exampleInputPassword1" class="form-label"
                  >Enter your mobile number if you wish to cancel
                </label>
                <input
                  v-mask="'880##########'"
                  masked="false"
                  type="tel"
                  name="mobileNumber"
                  class="form-control form-control-lg text-center"
                  placeholder="880XXXXXXXXXX"
                  v-model="mobileNumber"
                  required
                />
                <!-- <div
                  v-if="submitted && errors.has('mobileNumber')"
                  class="invalid-feedback"
                ></div> -->
              </div>

              <div class="mb-3 text-left">
                <label for="exampleInputPassword1" class="form-label"
                  >Select Channel:
                </label>
                <select
                  v-model="channel"
                  class="form-control form-control-lg"
                  required
                >
                  <option
                    v-for="(channel, i) in channels"
                    :key="i"
                    :value="channel.value"
                  >
                    {{ channel.text }}
                  </option>
                </select>
              </div>
              <button type="submit" class="btn btn-lg btn-primary w-100">
                Cancel Subscription
              </button>
              <!-- <div class="my-3">
                <router-link to="/" disabled="true">Cancel</router-link>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <div class="container mb-3">
        <div class="row justify-content-center align-items-center">
          <!-- <div class="col-lg-12">
            <div class="my-4">
              <h1 class="text-uppercase text-left">
                HOW TO CANCEL SUBSCRIPTION
              </h1>
              <div class="horizontal-divider m-0"></div>
            </div>
          </div> -->
          <!-- <div class="col-lg-5">
            <p class="text-justify">
              <strong>To cancel your subscription via SMS Banglalink:</strong>
            </p>
            <p class="text-justify">
              For daily, Write <b>STOP FBD</b> and send it to <b>27575</b>
            </p>
            <p class="text-justify">
              For weekly, Write <b>STOP FBW</b> and send it to <b>27575</b>
            </p>

            <p class="text-justify">
              For monthly, Write <b>STOP FBM</b> and send it to
              <b>27575</b>
            </p>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileNumber: "",
      channel: "47090",
      channels: [
        { text: "Bkash", value: "47090" },
        { text: "Banglalink", value: "47003" },
        { text: "Grameenphone", value: "47001" },
        { text: "Robi", value: "47002" },
      ],
    };
  },

  methods: {
    async onSubmit() {
      // console.log("asdasd");
      this.$store.dispatch("ADD_CHANNEL", this.channel);
      this.$store
        .dispatch(
          "ADD_CANCELMOBILENUMBER",
          this.mobileNumber.replace(/\s+/g, "").replace("+", "")
        )
        .then(() => {
          this.$router.push({ path: "cancel" });
        })
        .catch(() => {
          this.$swal(
            "Oops!",
            "Something went wrong. Please try again.",
            "warning"
          );
        });
    },
  },
};
</script>
<style scoped>
section.bg-gray {
  background-color: #f3f3f3;
}
</style>
